<template>
<div class="wrapper">
  <b-form-input
   :id="id"
   autocomplete="off"
   class="invision-input"
   :name="name"
   :disabled="disabled"
   :state="state"
   :value="value"
   @input="handleChange"
   @focus="setOpenSuggestions"
   @blur="setOpenSuggestions"
  />
  <div>
    <div
      class="suggestions-list"
      :class="{'suggestions-is-open': suggestions.length >= maxItemsToScrollbar }"
      v-if="openSuggestions === true"
    >
      <div v-if="suggestions.length">
        <SuggestionItem
          v-for="(item, index) in suggestions"
          :key="index"
          :id="index"
          :content="item[itemContentIdentifier]"
          @item-data="(itemData) => setSuggestion(itemData)"
        />
      </div>
      <div class="status" v-else>
        <p>{{ status }}</p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import GenericApi from '@/services/genericRequest';
import { debounce } from '@/helpers/common';
import SuggestionItem from './SuggestionItem';

export default {
  name: 'InputWithSuggestions',
  components: {
    SuggestionItem,
  },
  props: {
    value: {
      type: String,
    },
    route: {
      type: String,
      required: true,
    },
    api: {
      type: Object,
      required: false,
      default() {
        return GenericApi;
      },
    },
    apiMethod: {
      type: String,
      required: false,
      default() {
        return 'get';
      },
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    state: {
      type: Boolean,
      required: false,
      default: null,
    },
    itemContentIdentifier: {
      type: String,
      required: true,
    },
    minSearchLength: {
      type: Number,
      required: false,
      default: 4,
    },
    maxItemLength: {
      type: Number,
      required: false,
      default: 10,
    },
    maxItemsToScrollbar: {
      type: Number,
      required: false,
      default: 3,
    },
  },

  data() {
    return {
      suggestions: [],
      noResults: false,
      openSuggestions: false,
      loading: false,
    };
  },
  methods: {
    setSuggestion(itemData) {
      const { content } = itemData;
      this.$emit('input', content.toUpperCase());
    },
    setOpenSuggestions(e) {
      if (e.type === 'focus') this.openSuggestions = true;
      if (e.type === 'blur') this.openSuggestions = false;
    },

    handleChange(v) {
      if (v.length >= this.minSearchLength) {
        this.noResults = false;

        this.debouncedSearch(v);
      } else {
        this.suggestions = [];
      }
      this.$emit('input', v.toUpperCase());
    },

    debouncedSearch: debounce(function debouncing(v) {
      this.search(v);
    }, 600),

    async search(value) {
      if (!value) return;
      if (value.length < this.minSearchLength) return;

      try {
        const res = await this.api[this.apiMethod]({ userInput: value }, `${this.route}/${value}`);
        if (res) {
          if (!res.length) this.noResults = true;

          const newSuggestions = res.slice(0, this.maxItemLength);

          this.suggestions = newSuggestions;
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
        this.suggestions = [];
      }
    },
  },
  computed: {
    status() {
      if (!this.suggestions) return 'Temos problemas para encontrar sugestões';

      if (this.value.length < this.minSearchLength) {
        return `Por favor, digite ao menos ${this.minSearchLength} caracteres`;
      }
      if (this.noResults) {
        return `Não foram encontrados resultados para: '${this.value}'`;
      }
      return 'Por favor, aguarde um momento...';
    },
  },
  watch: {
    value(value) {
      if (value.length < this.minSearchLength || value.length < 1) {
        this.suggestions = [];
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
}

.suggestions-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  z-index: 2;
  width: 100%;
  list-style: none;
  background-color: #fefefe;
  border-radius: 4px;
  margin-top: 5px;
  padding: 0;
  border: 1px solid #21cca9;
}
.status {
  margin: 0;
  padding: 10px;
}

.status p {
  margin: 0;
  padding: 0;
  text-align: center;
}
.suggestions-is-open{
  height: 200px;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: inherit;
}
::-webkit-scrollbar-thumb {
  background: #328b63;
}
::-webkit-scrollbar-thumb:hover {
  background: #276e4e;
}
</style>
